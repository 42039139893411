import React, { useEffect, useState, useContext } from "react";
import { SurveyContext } from "../../utils/surveyContext";
import ProgressBar from "../molecules/progressBar";
import Required from "../atoms/required";

import { makeStyles } from '@material-ui/core/styles';

import TextField from "../atoms/textField";
import Button from "../atoms/button";
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';

import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    wrap: 'nowrap',
      "& .MuiTextField-root": {
        marginTop: theme.spacing(1),
        width: "100%",
      },
  }, 
}));

const StepEvent = () => {
  const step = "intro";
  const { surveyStep, setSurveyStep } = useContext(SurveyContext);
  const methods = useForm({ mode: "onBlur" });
  let history = useHistory();
  const onSubmit = () => {
    history.push(`/survey/about`);
  };
  const classes = useStyles();
  return (
    <div>
      <ProgressBar title="INTRO" pct="16" step="0"/>
      <div className={classes.root}>
      <Grid container justifyContent="center">
      <Grid item  xs={12} md={10}>
        <Box p={4} style={{backgroundColor:'#9ED2CB'}}>
        <Typography component="div" variant="body1">
        <Box fontWeight="fontWeightBold"> WELCOME!</Box>
          <Box mb={2}>
          Here, you can create your own beautiful video to see how your event would be in Copenhagen.
          </Box>
          <Box mb={2}>
          Answering the questions is simple and will only take around  <b>3-5 minutes.</b></Box>
          <Box >It’s pretty straight forward - and remember: If there are questions you can’t answer yet, just click “Not applicable”. You can always make another video at another time.</Box>
       </Typography>
       </Box>
       <FormProvider {...methods} > 
       <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container justifyContent="flex-end" spacing={1}>
       
          <Grid item xs={12}>
          <Typography component="div"  variant="body1">
        <Box fontWeight="fontWeightBold" mt={3}>
          First, what is your name? <span style={{color:'#F58C1E',fontFamily: 'Montserrat',fontWeight:'400'}}> *</span>
        </Box>
        </Typography>
          </Grid>
          <Grid item xs={12}  md={6}>
          <TextField
           content={"First name"}
           fieldName={"firstName"}
           required={true}/>
          </Grid>
         
          
          <Grid item xs={12}  md={6}>
          <TextField
           content={"Last name"}
           fieldName={"lastName"}
           required={true}/>
          </Grid>
          
          <Grid item xs={12}>
          <Required></Required>
          </Grid>

          <Grid item xs={4}  md={'auto'} alignself="flex-end">
          <Button onClick={methods.handleSubmit(onSubmit)} caption={"Next"}/>
          </Grid>
     
        </Grid>
        </form>
      </FormProvider>
        </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default StepEvent;
