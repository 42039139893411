import React , {useContext, useEffect } from "react";
import { SurveyContext } from "../../utils/surveyContext";

import { makeStyles } from "@material-ui/core/styles";
import {
  FormLabel,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Checkbox,
  Box,
  Typography,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    // marginTop: theme.spacing(3),
  },
}));

export default function CheckboxesGroup({ head, title }) {
  const classes = useStyles();

  const { surveyStep, setSurveyStep } = useContext(SurveyContext);
  const [state, setState] = React.useState({
    networking:surveyStep['afterHour']?surveyStep['afterHour'].includes('networking'):false,
    dining:surveyStep['afterHour']?surveyStep['afterHour'].includes('dining'):false,
    nightlife:surveyStep['afterHour']?surveyStep['afterHour'].includes('nightlife'):false,
    shopping:surveyStep['afterHour']?surveyStep['afterHour'].includes('shopping'):false,
    artAndCulture:surveyStep['afterHour']?surveyStep['afterHour'].includes('artAndCulture'):false,
    sightseeing:surveyStep['afterHour']?surveyStep['afterHour'].includes('sightseeing'):false,
  });
  const handleChange = (event) => {
    setState(state => ({ ...state,[event.target.name]:  event.target.checked}))  

  };
  useEffect(()=>{
    let array=[]
    Object.entries(state).map((entry) => {
      let [key, value] = entry;
      if(value==true){
        array.push(key)
      }
    })
    setSurveyStep(surveyStep => ({ ...surveyStep,['afterHour']:  array}))
  },[state])

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel style={{ fontWeight:'bold'}}  component="legend">{head}<span style={{color:'#F58C1E'}}>* </span></FormLabel>

        <Box mt={2} mb={1}>
        <FormHelperText component="div" style={{ textTransform:'uppercase'}}>
          <Typography component="div" variant="body1">
              {title}
          </Typography>
        </FormHelperText>
        </Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.networking}
                onChange={handleChange}
                name="networking"
              />
            }
            label="Networking"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.dining}
                onChange={handleChange}
                name="dining"
              />
            }
            label="Dining"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.nightlife}
                onChange={handleChange}
                name="nightlife"
              />
            }
            label="Nightlife"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.shopping}
                onChange={handleChange}
                name="shopping"
              />
            }
            label="Shopping"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.artAndCulture}
                onChange={handleChange}
                name="artAndCulture"
              />
            }
            label="Art and culture"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.sightseeing}
                onChange={handleChange}
                name="sightseeing"
              />
            }
            label="Sightseeing"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
}
