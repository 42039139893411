import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Footer from "../components/organisms/footer";
import Header from "../components/organisms/header";
import {  Box } from "@material-ui/core";
import StepEvent from "../components/organisms/stepEvent.js";
import StepAbout from "../components/organisms/stepAbout.js";
import StepThemes from "../components/organisms/stepThemes.js";
import StepInterests from "../components/organisms/stepInterests.js";
import StepAlmost from "../components/organisms/stepAlmost";
import StepIntro from "../components/organisms/stepIntro";
import StepEnd from "../components/organisms/stepEnd";
import ScrollToTop from "../utils/scrollToTop";
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
    wrap: 'nowrap',
      "& .MuiTextField-root": {
        marginTop: theme.spacing(1),
        width: "100%",
      },
  }, 
}));

const SurveyPage = () => {
  // let { stepId } = useParams();
  const classes = useStyles();
  return (
    <Router>
     
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ width:'100%' }}
          >
            <Header title="LOGO" />
         <div className={classes.root}
            style={{ maxWidth: "1150px", width: "100%", padding: "0 2rem" }}>
                 <ScrollToTop />
        <Switch>
          <Route path="/survey/intro">
            <StepIntro />
          </Route>
          <Route path="/survey/about">
            <StepAbout />
          </Route>
          <Route path="/survey/event">
            <StepEvent />
          </Route>
          <Route path="/survey/themes">
            <StepThemes />
          </Route>
          <Route path="/survey/interests">
            <StepInterests />
          </Route>
          <Route path="/survey/almost">
            <StepAlmost />
          </Route>
          <Route path="/survey/end">
            <StepEnd />
          </Route>
        </Switch>
        </div>
        </Box>
          <Footer title="Universal footer" />
      
    </Router>
  );
};

export default SurveyPage;
