import React, {useContext} from "react";
import { SurveyContext } from "../../utils/surveyContext";
import ProgressBar from "../molecules/progressBar";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TextField from "../atoms/textField";
import Button from "../atoms/button";
import Required from "../atoms/required";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Typography, Box } from "@material-ui/core";
import SimpleSelect from "../atoms/selectInput";


const StepEvent = () => {
  const methods = useForm({ mode: "onBlur" });
  const { surveyStep, setSurveyStep } = useContext(SurveyContext);

  let history = useHistory();
  if (!surveyStep.firstName) {
    history.push(`/survey/intro`);
  }

  const onSubmit = () => {
    history.push(`/survey/event`);
  };

  const industryItems = {
    "Agriculture"                            : "Agriculture",
    "Commerce and Retail"                    : "Commerce and Retail",
    "Construction and Mining"                : "Construction and Mining",
    "Defense"                                : "Defense",
    "Education"                              : "Education",
    "Finance and Insurance"                  : "Finance and Insurance",
    "Forestry and Fishing"                   : "Forestry and Fishing",
    "Health Care and Pharma"                 : "Health Care and Pharma",
    "Hospitality and Food"                   : "Hospitality and Food",
    "IT and Data"                            : "IT and Data",
    "Legal Services"                         : "Legal Services",
    "Manufacturing"                          : "Manufacturing",
    "Marketing & Advertising"                : "Marketing & Advertising",
    "Publishing, Broadcasting, Entertainment": "Publishing, Broadcasting, Entertainment",
    "Scientific or Technical Services"       : "Scientific or Technical Services",
    "Telecommunications"                     : "Telecommunications",
    "Transportation and Logistics"           : "Transportation and Logistics",
    "Other Industry"                         : "Other Industry",
  }

  return (
    <Typography component="div">
      <ProgressBar title="About You" pct="33" step={1} />
      <Box fontWeight="fontWeightBold">
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            noValidate
            autoComplete="off"
          >
            <Grid container justifyContent="center" spacing={5}>
              <Grid item xs={12} md={10}>
                <TextField
                  head={
                    "What is the name of your company, society or association?"
                  }
                  content={
                    "E.g. “The International Union Architects” ,“Novo Nordisk”, “Microwave/Terahertz Science and Applications”"
                  }
                  fieldName={"companyName"}
                  multiline={true}
                  rows={"1"}
                  required={true}
                  maxLength={36}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="center"
                  style={{ backgroundColor: "#F5F5F5" }}
                  spacing={4}
                >
                  <Grid item xs={12} md={10}>
                    <Grid item xs={12} md={6}>
                      <SimpleSelect
                        required={true}
                        fieldName={"industry"}
                        title={"What kind of industry are you in?"}
                        items={industryItems}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={10}>
                <Grid item xs={12}>
                  <Required></Required>
                </Grid>

                <Grid container justifyContent="space-between">
                  <Grid item xs={4} md={1}>
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/survey/intro"
                      underline="none"
                    >
                      <Button variant="outlined" caption={"BACK"} />{" "}
                    </Link>
                  </Grid>
                  <Grid item xs={4} md={'auto'}>
                    <Button
                      onClick={methods.handleSubmit(onSubmit)}
                      caption={"NEXT"}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Typography>
  );
};

export default StepEvent;
