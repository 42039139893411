import React ,{useContext } from "react";
import { SurveyContext } from "../../utils/surveyContext";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../atoms/button";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

export default function UploadButtons() {
  const classes = useStyles();
  const { surveyStep, setSurveyStep } = useContext(SurveyContext);
  const handleChange = (event) => {
    setSurveyStep(surveyStep => ({ ...surveyStep,[event.target.name]:  event.target.value}))
  };
  return (
    <div className={classes.root}>
      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        type="file"
        name="file"
        onChange={handleChange}
      />
      <label htmlFor="contained-button-file">
        <Button variant="outlined" color="primary" component="span" caption={"CHOOSE FILE"}/>
      </label>
    </div>
  );
}
