import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
export default function Required() {

  return (
 
<Typography component="div" variant="body1" style={{color:'#F58C1E', fontFamily: 'Montserrat',fontWeight:'400',}}>
<Box mb={2} mt={2}>
  <span >*</span>required
  </Box>
  </Typography>
  );
}
