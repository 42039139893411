
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Logo from "../atoms/logo";
import Typography from '@material-ui/core/Typography';
const footer = (showLinks) => {
  return (
    <div style={{paddingTop:'70px',width:'100%', display: 'flex',flexDirection: 'column'}} >
    <Grid container alignItems="flex-end"  justifyContent="space-around" spacing={0}>

      {true && <Grid item xs={6}>
      <Typography component="div" variant="body2" component="div">
      <Box>
        <Link href="https://www.copcap.com" underline="always" color="inherit">www.copcap.com </Link>
        </Box>
        <Box>
        <Link href="https://www.wonderfulcopenhagen.dk" underline="always" color="inherit">www.wonderfulcopenhagen.dk </Link>
      </Box>
        <Link href="" underline="always" color="inherit">Data Protection Notice </Link>
        </Typography>
      </Grid>}

      <Grid item xs={2} sm={'auto'}>
        <Logo />
      </Grid>
      
    </Grid>
          <img style={{width:'100%'}} src="/Logo/cityline.png"/>
  </div>
  );
};

export default footer;
