import HeadTitle from "../atoms/headTitle";
import { Link } from "react-router-dom";
import Logo from "../atoms/logo";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const header = (props) => {
	const title = props.title || "undefined"
  return (
    <Box pt={2} pb={2}>
    <Grid container justifyContent="center">
      
      <Grid item xs={4} sm={6}>
      <Logo />
        {/* <Link to="/">HOME </Link>
        <Link to="/survey/intro">SURVEY </Link>
        <Link to="/result/12345">RESULT </Link> 
       */}
      </Grid>
    </Grid>
    </Box>
  );
};

export default header;
