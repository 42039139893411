import React, { useContext } from "react";
import { SurveyContext } from "../../utils/surveyContext";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Button from "../atoms/button";
// import SwitchButton from "../atoms/switchInput";
import ProgressBar from "../molecules/progressBar";
import WorkingTopics from "../molecules/workingTopics";
import {
  // BrowserRouter as Router,
  Link,
  // useParams,
} from "react-router-dom";

import TextField from "../atoms/textField";
import {
  FormControl,
  FormControlLabel,
  // FormHelperText,
  // InputLabel,
  // TextareaAutosize,
  Typography,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Required from "../atoms/required";
import RadioButton from "../atoms/radioInput";
const StepEvent = () => {
  const step = "themes";
  const { surveyStep, setSurveyStep } = useContext(SurveyContext);
  const methods = useForm({ mode: "onBlur" });
  let history = useHistory();

  if (!surveyStep.firstName) {
    history.push(`/survey/intro`);
  }

  const onSubmit = (data) => {
    // alert(JSON.stringify(surveyStep));
    history.push(`/survey/interests`);
  };


  const handleChange = (event) => {
    setSurveyStep((surveyStep) => ({
      ...surveyStep,
      [event.target.name]: event.target.value,
    }));
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiFormControlLabel-label": {
        // color: theme.palette.text.primary,
        // color:'#032584',fontSize: 18,textTransform:'capitalize'
      },
    },
    grayBG: {
      backgroundColor: theme.palette.lightGray
    },
    asterisk: {
      color: theme.palette.text.hint,
      fontFamily: 'Montserrat',
      fontWeight:'400',
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ProgressBar
        title="About potential themes"
        color="#50BEAA"
        step={3}
        pct="66"
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container justifyContent="center" spacing={5}>
            <Grid item xs={12} md={10}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <Typography component="div" color="primary">
                    <Box fontWeight="fontWeightBold">
                      Does your event have an overarching (working) theme yet?<span className={classes.asterisk}> *</span>
                    </Box>
                  </Typography>
                </FormLabel>
                <RadioGroup
                  name="isWorkingTheme"
                  aria-label="Does your event have an overarching (working) theme yet?"
                  value={surveyStep['isWorkingTheme']}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="yes"
                    style={{color:'#032584',fontSize: 18,textTransform:'capitalize'}}
                    control={<Radio style={{marginRight:'1rem'}}/>}
                    label="Yes"
                  />
                  <FormControlLabel 
                  value="no" 
                  style={{color:'#032584',fontSize: 18,textTransform:'capitalize'}}
                  control={<Radio style={{marginRight:'1rem'}} />} 
                  label="No" 
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={10}>
              <TextField
                title={"Write the umbrella theme for your event"}
                content={
                  "E.g. “Life Sciences - from molecules to complex systems” or “Revolutionizing Rheumatology” or “Strategic Farming in Scandinavia”"
                }
                required={false}
                fieldName={"eventTheme"}
                multiline={true}
                rows={2}
                disabled={surveyStep['isWorkingTheme'] === "no" ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="center"
                className={classes.grayBG}
                spacing={3}
              >
                <Grid item xs={12} md={10}>
                  <RadioButton
                    defaultValue={"no"}
                    disabled={surveyStep['isWorkingTheme'] === "no" ? true : false}
                    fieldName={"IsEventTitle"}
                    title={"Is there a (working) title of your event?"}
                    values={["yes", "no"]}
                    style= {{marginRight:'1rem'}}
                  />
                  <TextField
                    disabled={surveyStep['isWorkingTheme'] === "no" ? true : false}
                    title={"Write the (working) title for your event"}
                    content={
                      "Eg. “Sustainable Futures – Leave No One Behind” or “From Cradle to Cognitive Reserve”"
                    }
                    required={false}
                    fieldName={"eventTitle"}
                    multiline={true}
                    rows={2}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={10}> 
              <WorkingTopics
                disabled={surveyStep['isWorkingTheme'] === "no" ? true : false}
              />
              <Required></Required>

              <Grid container justifyContent="space-between">
                <Grid item xs={4} md={1}>
                  <Link to="/survey/event" style={{ textDecoration: "none" }} underline="none">
                    <Button variant="outlined" caption={"BACK"} />{" "}
                  </Link>
                </Grid>
                <Grid item  xs={4} md={'auto'}>
                  <Button
                    onClick={methods.handleSubmit(onSubmit)}
                    caption={"NEXT"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
};

export default StepEvent;
