import React from "react";
// import Container from "@material-ui/core/Container";


import SurveyPage from "./pages/survey";
import ResultPage from "./pages/result";
import VideoPage from "./pages/video";
import TestPage from "./pages/test";
import UploadTest from "./pages/upload";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { createTheme, ThemeProvider, Box } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import WebFont from "webfontloader";
import styles from "./index.css";
WebFont.load({
  typekit: {
    id: "beg7kuq",
    families: ["Nobel:200,300,400,700,800", "sans-serif"],
  },
});

const theme = createTheme({
  typography: {
    body1: {
      fontFamily: "Nobel",
      fontSize: "18px",
      fontWeight:300,
    },
    body2: {
      fontFamily: "Nobel",
        fontSize: "14px",
    },
    h6: {
      fontFamily: "Nobel",
      fontSize: "40px",
      fontWeight: "300",
      color:'white'
    },
    subtitle1: {
      fontFamily: "Nobel",
      fontSize: "18px",
      fontWeight: "bold",
      color: "black",
      lineHeight: "22px",
    },
    overline: {
      fontFamily: "Nobel",
      fontSize: "14px",
      textTransform: "none",
      color: "#EB1964",
    },
    h5: {
      fontFamily: "Nobel",
      fontSize: "40px",
      textTransform: "uppercase",
      "@media (max-width:600px)": {
        fontSize: "30px",
      },
    },
    subtitle2: {
      fontFamily: "Nobel",
      fontSize: "16px",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": "Nobel",
      },
    },
  },
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#000",
    },
    lightGray:'#F5F5F5',
    lighterGray:'#E2E2E2',
    darkGray:'#707070',
    white:'#fff',
    text: {
      primary: "#000",
      secondary: "#000",
      hint: "#F58C1E",
      blue:"#032584",
    },
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
      <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          sx={{ minHeight: "100vh" }}
        >
            <Switch>
              <Route path="/survey/:stepId" component={SurveyPage} />
              <Route path="/result/:resultId" component={ResultPage} />
              <Route path="/video/:videoId" component={VideoPage} />
              <Route path="/test" component={TestPage} />
              <Route path="/upload" component={UploadTest} />
              <Route exact path="/">
                <Redirect to="/survey/intro" />
              </Route>
            </Switch>
            </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
