import React, { createContext, useState } from "react";

export const SurveyContext = createContext(null);

export default ({ children }) => {
  const date = new Date();
  const [month, day, year]= [date.getMonth(), date.getDate(), date.getFullYear()];
  const [surveyStep, setSurveyStep] = useState({startDate:`${day}.${month+1}.${year}`,endDate:`${day}.${month+1}.${year}`,preferedDate:false,delegatesQty:'<500',isWorkingTheme:"no",IsEventTitle:"no",IsForSomeoneElse:"no",mailAgreement:false,setRotation:''  });

  const store = {
    survey: [surveyStep, setSurveyStep],
  };

  return (
    <SurveyContext.Provider value={{ surveyStep, setSurveyStep }}>
      {/* <div>{ surveyStep.foo }</div> */}
      {children}
    </SurveyContext.Provider>
  );
};
