import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { SurveyContext } from "../../utils/surveyContext";
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    position: "relative",
  },
  asterisk: {
    color: theme.palette.text.hint,
    fontFamily: 'Montserrat',
    fontWeight:'400',
  },
  selectEmpty: {
    color: theme.palette.text.blue, fontSize: 18, fontWeight:300, 
  },
  menuItem: {
    color: theme.palette.text.blue, fontSize: 18, fontWeight:300 
  },
}));

export default function SimpleSelect({ title, fieldName,label, required, items ,fontStyle}) {
  const classes = useStyles();
  const { surveyStep, setSurveyStep } = useContext(SurveyContext);
  const handleChange = (event) => {
    // setSurveyStep((surveyStep) => ({
    //   ...surveyStep,
    //   [event.target.name]: event.target.value
    // }));
    if(fieldName=='openYear'){    
      setSurveyStep((surveyStep) => ({
      ...surveyStep,
      [event.target.name]: event.target.value,['startDate']: `01.01.${event.target.value}`,['endDate']:`01.01.${event.target.value}`
    }));}
    else{
      setSurveyStep((surveyStep) => ({
        ...surveyStep,
        [event.target.name]: event.target.value
      }));
    }
  };
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box mt={1}>
      <FormControl required className={classes.formControl}>
        {title &&<FormHelperText component="div">
          <Typography component="div" variant={fontStyle == 'body1'? 'body1':'subtitle1'} color="primary">
            <Box mb={2}>
              {title}
              {required && <span className={classes.asterisk}> *</span>}
            </Box>
          </Typography>
        </FormHelperText>}
        <Select
          labelId="demo-simple-select-placeholder-label-label"
          id="demo-simple-select-placeholder-label"
          {...register(fieldName, { required: required, maxLength: 200 })}
          value={surveyStep[fieldName] ? surveyStep[fieldName] : ""}
          onChange={handleChange}
          displayEmpty
          autoWidth
          className={classes.selectEmpty}
        >
          <MenuItem className={classes.menuItem} value="">{label? label:'Choose here'} </MenuItem>

          {Object.entries(items).map((entry) => {
            let [key, value] = entry;
            return (
              <MenuItem className={classes.menuItem} key={key} value={key}>
                {value} {fieldName=='number'? `(${key})` : ''}
              </MenuItem>
            );
          })}
        </Select>
        {errors[fieldName] && (
          <Typography component="div" variant="overline">This is required </Typography>
        )}
      </FormControl>
    </Box>
  );
}
