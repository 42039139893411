import React,  {useContext }  from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { SurveyContext } from "../../utils/surveyContext";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginTop: theme.spacing(1),
      width: "100%",
    },
  },
  asterisk: {
    color: theme.palette.text.hint,
    fontFamily: 'Montserrat',
    fontWeight:'400',
  },
}));
export default function FormPropsTextFields({ head,fieldName, title, content,rows,required,disabled,multiline,pattern,termination,type,maxLength}) {
  console.log(maxLength)
  const classes = useStyles();
  const { surveyStep, setSurveyStep } = useContext(SurveyContext);
  const [ suffix, setSuffix ] = React.useState(surveyStep['setRotation'].replace(/\d+/,""));
  function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}
  const handleChange = (event) => {
    if(termination){
    setSuffix(ordinal_suffix_of(event.target.value).replace(/\d+/,""))
    setSurveyStep(surveyStep => ({ ...surveyStep,[event.target.name]:  ordinal_suffix_of(event.target.value)}))

    }else{
      setSurveyStep(surveyStep => ({ ...surveyStep,[event.target.name]:  event.target.value}))
    }
    console.log(surveyStep)
  }; 
  const { register,  control,formState: { errors }, } = useFormContext();
  return (
    <Box mt={0} style={{position:'relative'}}>
        
        <Typography component="div" variant="subtitle1" color="primary">
           <Box style={{color:disabled?'#707070':'black'}}>
           {head} {head && required && <span className={classes.asterisk}> *</span>}
            </Box>
        </Typography>
        {title &&
          <Typography component="div" variant={'body1'} color="primary">
            <Box mb={2}  style={{color:disabled?'#707070':'black'}}>
              {title}
            </Box>
          </Typography>}
            <TextField  
            style= {{ border: multiline ? '1px solid #E2E2E2':''}}
            inputProps={{style: {color:'#032584',fontSize: 18,fontWeight:300, padding:'0.25rem 1rem',opacity:disabled?'50%':'100%'}, maxLength:maxLength?maxLength:'500'}} 
            control={control} 
            defaultValue={fieldName=='setRotation' ?surveyStep[fieldName].replace(/[A-Za-z]+/,""):surveyStep[fieldName]}  
            {...register(fieldName, { required: required, maxLength: 200,pattern: pattern?pattern:'' })} 
            required={required} 
            id="standard-required"
            placeholder={content}
            multiline={multiline}
            rows={rows}  
            onChange={handleChange}
            type={type}
            disabled={disabled} />
            {termination&&<span style={{position:'absolute',bottom:'0'}}>{suffix}</span>}
          {errors[fieldName] && <Typography component="span" variant="overline">This is required </Typography>}
         
          </Box>
  );
}
