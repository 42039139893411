import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box';

const DefaultButton = (props) => {
	const caption = props.caption || "undefined"
  const variant = props.variant || "contained"
  const color = props.color || "primary"
  return <Button color={color} variant={variant} style={{fontFamily:'Nobel',fontSize:14, padding: '10px 40px',borderRadius: '0',borderColor:'black'}} onClick={props.onClick}>
   <Box fontWeight="fontWeightBold"> {caption}</Box>
    </Button>;
}

export default DefaultButton;
