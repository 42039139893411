import React, { useContext } from "react";
import { SurveyContext } from "../../utils/surveyContext";
import ProgressBar from "../molecules/progressBar";
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Link, useParams } from "react-router-dom";
import { Typography, Box, Grid } from "@material-ui/core";
import LinkUI from '@material-ui/core/Link';
const StepEvent = () => {
  const step = "end";
  const { surveyStep, setSurveyStep } = useContext(SurveyContext);

  const result = surveyStep.resultId || "FNORD";

  // let history = useHistory();
  // if (!surveyStep.firstName) {
  //   history.push(`/survey/intro`);
  // }

  return (
    <div>
      <ProgressBar title="THANK YOU!" color="#00CDD7" step={5} pct="100" />
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={0}
      >
        <Grid item xs={12} md={10}>
          <Box p={4} style={{ backgroundColor: "#9ED2CB" }}>
            <Typography>
              <Box fontWeight="fontWeightBold" mb={2}>
                Your video is now being generated. In a short while, you’ll
                receive an email with a link to watch and download it.
              </Box>
              <Box mb={2}>
                You are more than welcome to use the video in presentations and
                to share it with others.
              </Box>
              <Box mb={2}>
                And remember: Don’t hesitate to contact us if you want to hear
                more about our complimentary services - or wonderful Copenhagen,
                of course.{" "}
              </Box>
              <Box>Best regards, </Box>
              <Box mb={2}>The Copenhagen Convention Bureau Team </Box>

              {/* <Box mb={2}>
                {result} <br />
                <LinkUI href={"/video/" + result}>VIDEO LINK</LinkUI>
              </Box> */}

              <LinkUI href="https://invitationto.greatercph.com/" underline="always" color="inherit">
                Go to website
              </LinkUI>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default StepEvent;
