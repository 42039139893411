import React, { useEffect, useState, useContext } from "react";
import { SurveyContext } from "../../utils/surveyContext";
import TextBlock from "../atoms/textBlock";
import Button from "../atoms/button";
import ProgressBar from "../molecules/progressBar";
import {
  BrowserRouter as Router,
  Link,
  useParams,
} from "react-router-dom";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import CheckBoxInput from "../atoms/checkBoxInput";
import Required from "../atoms/required";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    width:'100%',
  "& .MuiFormControlLabel-label":{
   color: theme.palette.text.blue
  },
  }
}));
const StepEvent = () => {
  const step = "interests";
  const { surveyStep, setSurveyStep } = useContext(SurveyContext);
  const [error, setError] = React.useState(false);
  let history = useHistory();
  if (!surveyStep.firstName) {
    history.push(`/survey/intro`);
  }

  const onSubmit = (data) => {
    let checkboxes=document.querySelectorAll("input[type=checkbox]")
    for (let checkbox of checkboxes) {
      if(checkbox.checked == true){
        setError(false)
        history.push(`/survey/almost`);
        break;
      }
      else{
        setError(true)
      }
    }
  };
  const classes = useStyles();
  return (
    <div  className={classes.root}>
      <ProgressBar title="About your Interests" step={4} pct="83" />
      <Grid container justifyContent="center" spacing={5}>
            <Grid item xs={12}  md={10}>
          <CheckBoxInput
            head="What are after hours-interests of your delegates?"
            title="Choose minimum one"
          />
          {error && <Typography component="span" variant="overline">Please select at least one </Typography>}
          <Required></Required>
          <Grid container justifyContent="space-between">
            <Grid item xs={4}  md={'auto'}>
              <Link to="/survey/themes" style={{ textDecoration: "none" }} underline="none"><Button variant="outlined" caption={"BACK"}/> </Link>
            </Grid>
            <Grid item xs={4}  md={'auto'}>
              <Button
                onClick={onSubmit}
                caption={"NEXT"}
              />
            </Grid>
       

            </Grid>
            </Grid>
      </Grid>

    </div>
  );
};

export default StepEvent;
