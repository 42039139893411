import React,  {useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { SurveyContext } from "../../utils/surveyContext";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: '100%',
  },
}));

export default function DatePickers({ label,fieldName }) {
  const classes = useStyles();
  const { surveyStep, setSurveyStep } = useContext(SurveyContext);
  const handleDateChange = (date,dateString) => {
    setSurveyStep(surveyStep => ({ ...surveyStep,[fieldName]: date.getDate()+'.'+parseInt(date.getMonth()+1)+'.'+date.getFullYear()  }))
  };
  return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
       <KeyboardDatePicker
          disableToolbar
          className={classes.datePicker}
          keyboardIcon={<CalendarTodayOutlinedIcon/>}
          variant="inline"
          format="dd/MM/yyyy"
          mask={"__.__.____"}
          maskChar="_"
          margin="normal"
          inputVariant='standard'
          id="date"
          value={new Date(surveyStep[fieldName].replace(/\./g, "-").split("-").reverse().join("-"))}
          onChange={handleDateChange}
          disablePast={true}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        </MuiPickersUtilsProvider>
  );
}
