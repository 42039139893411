import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";

const UploadTest = () => {

  // let { resultId } = useParams();
  // const url = "http://localhost:5001/copcapapp/europe-west1/getVideo?id="+resultId;
  // const url = "https://europe-west1-copcapapp.cloudfunctions.net/getVideo?id="+resultId;
  // const url = "http://localhost:5001/copcapapp/europe-west1/testVideo";
  // const url = "https://europe-west1-copcapapp.cloudfunctions.net/testVideo";

  const url = "";
	

	
	const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(url);
      const newData = await response.json();
      console.warn(response)
      setData(newData);
    };
    fetchData();
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      {/* <h1>VIDEO ID: {resultId}</h1> */}
      {/* <div>{JSON.stringify(data)}</div> */}
      <div dangerouslySetInnerHTML={{ __html: data.sdk }}></div>
    </div>
  );
};

export default UploadTest;
