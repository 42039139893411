import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
// const useStyles = makeStyles({
//   root: {
//     width: '100%',
//     paddingTop:'20px',
//     '& .MuiLinearProgress-bar1Determinate':{
//       background:'#0000003d',
//       height: '10px',
//     },
//   },
//   bar:{
//     width:'100%',
//     position:'relative',
//     display:'flex',
//   },
//   step:{
//     width:'17%',
//     height:'10px',
//   },
//   pct:{
//     width:'40px',
//     color:'#707070',
//     position:'absolute',
//     display:'flex',
//     flexDirection:'column',
//     bottom:'0px',
//     alignItems: 'center',
//     lineHeight:'1'
//   }
// });


export default function LinearProgressWithLabel(props) {
  const title = props.title || "undefined";
  const pct = props.pct || "undefined";
  const color = props.color || undefined;
  const step =props.step ||  "undefined";
  let colors= ['#EB1964', '#DFF7EF','#CCECE6', '#B7CED8','#E3CAE1','#FAE8E9'];
  let newColors = ['#EB1964', '#91E6C8','#50BEAA', '#085578','#AF64AA','#F0AFB4'];
  //change progress bar colors according to current step
  for(let i=0; i<=step; i++){
    colors[i]=newColors[i];
  }
  const useStyles = makeStyles({
    root: {
      width: '100%',
      paddingTop:'20px',
      '& .MuiLinearProgress-bar1Determinate':{
        background:'#0000003d',
        height: '10px',
      },
    },
    bar:{
      width:'100%',
      position:'relative',
      display:'flex',
    },
    step:{
      width:'17%',
      height:'10px',
    },
    pct:{
      width:'40px',
      color:'#707070',
      position:'absolute',
      display:'flex',
      flexDirection:'column',
      bottom:'0px',
      alignItems: 'center',
      lineHeight:'1',
      left:`calc((181px * ${step+1}) - 20px)`,
      "@media (max-width:1150px)": {
        left:`calc((16.66% * ${step+1}) - 20px)`,
      },
    }
  });
  const classes = useStyles();
  return (
    <div className={classes.root}>
    <Box className={classes.bar}>
    {colors.map(color =>
    <div key={color} className={classes.step} style={{backgroundColor: color}}></div>
      )}
    <div className={classes.pct}>  <Typography component="span"  variant="subtitle2">{pct}% </Typography><span>|</span></div>
    </Box>
    <Box pt={5} pb={3}>
    <Grid container justifyContent="center">
          <Grid item xs={12} md={10}>
            <Typography variant="h5" style={{color: color ? color : colors[step] } }>
              {title}
              </Typography>
          </Grid>
      </Grid>
      </Box>
    </div>
  );
}

