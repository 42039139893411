import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  Typography,
  Box,TextField,Checkbox,FormControlLabel,
  Card,CardMedia,Link,Button,
  createTheme, ThemeProvider
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { bg } from "date-fns/locale";
import Logo from "../components/atoms/logo";
const theme = createTheme({
  overrides: {
    MuiInputLabel: { 
      root: { 
        color: "white",
        fontSize: '16px', fontWeight:'bold',fontFamily:'Nobel',
        "&$focused": {
          color: "white",
        },
      }
    },
    MuiInputBase: { 
      root: { 
        "&::before": {
          borderBottom: "2px solid white !important" ,
        },
        "&::after": {
          borderBottom: "2px solid white !important" ,
        },
      }
    },
  }
});
const useStyles = makeStyles({
  root: {
    maxWidth: "1086px",
    '.MuiGrid-container': {
      maxWidth: "1086px"
    },
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
    borderRadius:'5px',
    height:'350px',width:'350px',maxWidth:'100%',
    "@media (max-width:400px)": {
      padding:'2rem'
    },
  },
  CheckBoxLabel: {
    color:'#fff',fontSize: '16px', fontWeight:'bold',fontFamily:'Nobel',
  },
  sendButton: {
    color:'#fff',fontSize: '16px', fontWeight:'bold',fontFamily:'Nobel',border:'1px solid white', borderRadius:0,padding: '5px 60px',textTransform:'uppercase'
  },
  textField: {
    width:"100%"
  },
  textFieldLabel: {
    color:'#fff',fontSize: '16px', fontWeight:'300',fontFamily:'Nobel'
  },
  underline: {
    color:'#fff'
  },
  title:{
    textAlign:"center", fontFamily:"Nobel" ,fontWeight:"bold" ,fontSize:'70px',
    "@media (max-width:600px)": {
      fontSize: "30px",
    },
  },
  infoSection:{
    textAlign:"left" ,lineHeight:"44px",margin:'2rem 0',
    fontSize: '40px',fontFamily:'Nobel',fontWeight:'400',
    "@media (max-width:600px)": {
      fontSize: "20px",lineHeight:"24px"
    },
  }
});
const VideoPage = () => {
  const classes = useStyles();
  let { videoId } = useParams();
  // const url = "http://localhost:5001/copcapapp/europe-west1/getVideo?id="+videoId;
  const url = "https://europe-west1-copcapapp.cloudfunctions.net/getVideo?id="+videoId;
  // const url = "https://europe-west1-copcapapp.cloudfunctions.net/getVideo?id="+'NpGJNk7eLuxrVmxt8V1p';
  const [data, setData] = useState([]);
  const [downloadLink, setDownloadLink] = useState('');
  function copyToClipboard() {
    let link = document.getElementsByTagName('source')[1]?document.getElementsByTagName('source')[1].src:''
    navigator.clipboard.writeText(link);
    // alert("Copied to clipboard");
  }
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(url);
      const newData = await response.json();
      setData(newData);
      setDownloadLink(document.getElementsByTagName('source')[1]?document.getElementsByTagName('source')[1].src:'')
    };
    fetchData();
  }, []);
  return (

         <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ width:'100%',paddingTop:'3rem' }}
          >
      {/* <h1>VIDEO ID: { videoId }</h1> */}
      {/* <div>{JSON.stringify(data)}</div> */}
      <Box pt={2} pb={2}>
    <Grid container justifyContent="center">
      <Grid item xs={6} md={3}>
      <img style={{width:'100%',minWidth:'50px',maxWidth:'100%'}} src="/Logo/CCB.png"/>
      </Grid>
    </Grid>
    </Box>
      <Box pt={5} pb={5}  display="flex" justifyContent="center" sx={{width:'100%',padding:'2rem' }}>
      <Grid container alignItems="center" justifyContent="center" spacing={0} className={classes.root}>
      <Grid item xs={12} >
            <Typography component="div" variant="body1"  color="primary">
            <Box  mb={2} className={classes.title}>
            YOUR PROMO VIDEO
              </Box>
            </Typography>
        </Grid>
      <Grid item xs={12} md={10}>
      <div style={{width:'100%',display:'flex',justifyContent:'center', marginBottom:'3rem'}}  dangerouslySetInnerHTML={{ __html: data.sdk }}></div>
        </Grid>
        <Grid item xs={12} md={6}>
            <Typography component="div" variant="body1"  color="primary">
            <Box textAlign="center" sx={{ border:'1px solid black' , padding:'1rem 0'}} >
            <Link href={downloadLink} download underline="none" color="inherit">
            DOWNLOAD
              </Link>
              </Box>
            </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
        <Box textAlign="center" sx={{ border:'1px solid black' , padding:'1rem 0'}} >
        <Grid container>
        <Grid item xs={12} md={6}>
          <Typography component="div" variant="body1"  color="primary">
          <Box justifyContent="center"  display="flex" alignItems="center">
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="21" viewBox="0 0 21 21">
              <rect id="square" width="21" height="21"/>
            </svg>
            <Link href=" http://invitationto-video.greatercph.com/" underline="none" >
            CREATE ANOTHER VIDEO
            </Link>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component="div" variant="body1" color="primary">
          <Box  display="flex" alignItems="center" justifyContent="center" onClick={copyToClipboard} sx={{ cursor:'pointer'}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="18" viewBox="0 0 18 18">
            <circle id="Ellipse_3" data-name="Ellipse 3" cx="9" cy="9" r="9"/>
          </svg>
          COPY  VIDEO  LINK
            </Box>
          </Typography>
        </Grid>
        </Grid>
        </Box>
        </Grid>
        
      </Grid>
      </Box>
      <Box pt={20} pb={20} pl={4} pr={4}  display="flex" justifyContent="center" sx={{background:'url(/videoPage/map-bg.png)',backgroundSize:'cover',width:'100%' }}>
        <Grid container alignItems="center" justifyContent="center" spacing={4} className={classes.root}>
       
          <Grid item xs={12}  md={5}>
            <Typography component="div" variant="h6"  >
            <Box textAlign="left" fontWeight="fontWeightBold" lineHeight="40px" fontFamily="Nobel" fontSize="34px">
            Interested in hosting your event in Copenhagen? 
            </Box>
            <Box textAlign="left" fontWeight="fontWeightBold" lineHeight="40px" fontFamily="Nobel" fontSize="34px">
            Leave a message and we will get in touch
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12}  md={5}>
          <Box display="flex" justifyContent="center">
          <Button classes={{ root: classes.sendButton}}>
            <Link href=" https://invitationto.greatercph.com/#contact" underline="none" className={classes.underline}>
            Send US a message
            </Link>
            </Button>
            </Box>
          </Grid>
          
  
          </Grid>
          </Box>
        <Box pt={10} pb={10} pl={1} pr={1} display="flex" justifyContent="center" sx={{backgroundColor:'#50BEAA',width:'100%'}}>
          <Grid container alignItems="center" justifyContent="center" spacing={1} className={classes.root}>
       
          <Grid item>
            <img  className={classes.media}
              src="/videoPage/thumb.png"
              alt=""
              
            />
          </Grid>

         <Grid item xs={12}  md={7}>
         <Box pr={4} pl={4}>
         <Typography component="div"  variant="h6" >
            <Box textAlign="left" mb={2} fontWeight="fontWeightBold" fontFamily="Nobel" fontSize="30px"sx={{textTransform:'uppercase'}}>
            Discover why Copenhagen is a great choice for your event
              </Box>
          </Typography>
          <Box textAlign="left" color='white' fontSize="20px">
              <Link href="https://invitationto.greatercph.com/#inspiration" underline="always" className={classes.underline}>
              READ MORE
              </Link>
            </Box>
          </Box>
         </Grid>
         
         </Grid>
         </Box>
         <Grid container alignItems="center" justifyContent="center" spacing={0} className={classes.root}>
          <Grid item xs={12}>
          <Box p={4} className={classes.infoSection}>
              <Box mb={4}>
                Copenhagen Convention Bureau is the official convention bureau of the greater Copenhagen area.  
                </Box>
              <Box >
              Together with our wide spectrum of industry partners we offer all the support you need. 
              </Box>
            </Box>
          </Grid>
          
      </Grid>
      <Box  p={2}>
      <Grid container justifyContent="center"  >
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={4}  alignItems="flex-end" >
            <Grid item xs={4} sm={'auto'}>
            <img style={{width:'100%',minWidth:'50px',maxWidth:'180px'}} src="/Logo/CopenhagenCVB_2lines_black.png"/>
            </Grid>
            <Grid item xs={4} sm={'auto'}>
            <img style={{width:'100%',minWidth:'50px',maxWidth:'180px'}} src="/Logo/copenhagen-capacity.png"/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item >
        <Box p={4}>
          <Typography component="div" color="primary">
          <Box fontWeight="12" display="inline" fontFamily="Nobel" fontWeight="300" mr={2}>
          All rights reserved 
          </Box>
          |
          <Box fontWeight="12" fontFamily="Nobel" display="inline" fontWeight="300" mr={2} ml={2}>
          <Link href="https://www.wonderfulcopenhagen.com/convention-bureau/copenhagen-cvb-frontpage" underline="none">
          Copenhagen Convention Bureau 
            </Link>
          </Box>
          |
          <Box fontWeight="12" fontFamily="Nobel" display="inline"fontWeight="300" mr={2} ml={2}>
           
           <Link href="https://www.copcap.com/?__hstc=250010841.be076166db0cbc498a40649f74362430.1634662000722.1634674259596.1634719668419.3&__hssc=250010841.5.1634719668419&__hsfp=193293554" underline="none">
           Copenhagen Capacity
            </Link>
          </Box>
          |
          <Box fontWeight="12" fontFamily="Nobel" display="inline" fontWeight="300" mr={2} ml={2}>
          <Link href="https://www.copcap.com/data-protection-policy?__hstc=250010841.be076166db0cbc498a40649f74362430.1634662000722.1634674259596.1634719668419.3&__hssc=250010841.6.1634719668419&__hsfp=193293554" underline="none">
          Data Protection Policy 
            </Link>
          </Box>
          |
          <Box fontWeight="12" fontFamily="Nobel"display="inline" fontWeight="300" ml={2}>
          <Link href="https://www.copcap.com/cookie-policy?__hstc=250010841.be076166db0cbc498a40649f74362430.1634662000722.1634674259596.1634719668419.3&__hssc=250010841.6.1634719668419&__hsfp=193293554" underline="none">
          Cookie Policy
            </Link>
          </Box>
          </Typography>
          </Box>
        </Grid> 
      </Grid> 
      </Box>

    </Box>
  );
};

export default VideoPage;
