import React, { useContext }  from "react";
import LinkUI from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { SurveyContext } from "../../utils/surveyContext";
export default function CheckboxLabels() {


  const { surveyStep, setSurveyStep } = useContext(SurveyContext);
  const handleChange = (event) => {
    setSurveyStep((surveyStep) => ({
      ...surveyStep,
      [event.target.name]: event.target.checked
    }));
  };

  const { register,  control,formState: { errors }, } = useFormContext();
  return (
    <div>
    <FormControlLabel
       style= {{color:'#032584',fontSize: 18}}
      control={
        <Checkbox
        {...register("mailAgreement", { required: true, maxLength: 200 })}
          checked={surveyStep['mailAgreement']}
          onChange={handleChange}
          name="mailAgreement"
        />
      }
      label="Yes, Copenhagen Convention Bureau and Copenhagen Capacity can contact me via phone and email regarding event opportunities in Greater Copenhagen.*"
    />
    <Typography component="div">
    <Box ml={4} color="#032584">
    <LinkUI href="https://www.copcap.com/data-protection-policy" underline="none" color="inherit">
     Read more here
    </LinkUI>
     </Box>
     </Typography>
    {errors.mailAgreement && <Typography variant="overline">This is required </Typography>}
    </div>
  );
}
