
const logo = () => {
  return (

    <img style={{width:'100%',minWidth:'50px',maxWidth:'180px'}} src="/Logo/CCB.png"/>
 
  );
};

export default logo;
