import React, {useContext }  from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { SurveyContext } from "../../utils/surveyContext";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import TextField from "../atoms/textField";
import phone  from "../../constants/phone";
import countries from "../../constants/countries";
import SimpleSelect from "../atoms/selectInput";
import {
  Box,
  Grid,
  Typography
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  
}));

export default function BasicTextFields() {
  const classes = useStyles();
  const [contactInfo, setContactInfo] = React.useState( "");
  const handleChange = (event) => {
    const value = event.target.value;
    setContactInfo({
      ...contactInfo,
      [event.target.name]: value
    });
    setSurveyStep(surveyStep => ({ ...surveyStep,[event.target.name]:  event.target.value}))
  }; 


  const { register,  control,formState: { errors }, } = useFormContext();
  const { surveyStep, setSurveyStep } = useContext(SurveyContext);
  return (

    


        <Grid container spacing={2} style={{padding:'8px'}}>

        <Grid item xs={12} md={6}>
      <TextField
           content={"Your title"}
           fieldName={"yourTitle"}
           required={true}/>
        </Grid>

        <Grid item xs={12} md={6}>

      <TextField
           content={"Your company"}
           fieldName={"yourCompany"}
           required={true}/>
        </Grid>


        <Grid item xs={12} md={6}>
      <TextField
           content={"Email"}
           fieldName={"email"}
           pattern= {/^[-.\w]+@(?![^.]{0,1}\.[a-zA-Z]{1,}$)([-a-zA-Z0-9]+\.)+[a-zA-Z]{2,}$/}
           required={true}/>
        </Grid>
        
      
          <Grid item xs={2} md={2}>
          <SimpleSelect
        label={"0045 (DK)"}
                  required={false}
                  fieldName={"number"}
                  items={phone}
                  fontStyle={'body1'}
                />
          </Grid>

        <Grid item xs={9} md={4}>
        <TextField
           content={"Phone"}
           fieldName={"phone"}
           required={true}/>
          </Grid>

          <Grid item xs={12}  md={6}>
            <SimpleSelect
              required={true}
              label={"Country"}
              fieldName={"country"}
              items={countries}
              fontStyle={'body1'}
            />
          </Grid>


        </Grid>

      
      
           
  );
}
