import React, { useEffect, useState, useContext } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { SurveyContext } from "../../utils/surveyContext";
import Button from "../atoms/button";
import SwitchButton from "../atoms/switchInput";
import ProgressBar from "../molecules/progressBar";
import {
  BrowserRouter as Router,
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import TextField from "../atoms/textField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FormHelperText from "@material-ui/core/FormHelperText";
import SimpleSelect from "../atoms/selectInput";
import RadioButtonsGroup from "../atoms/radioInput";
import DatePickers from "../atoms/calendar";
import DatePickers2 from "../atoms/calendar2";
import Required from "../atoms/required";
import { withStyles } from '@material-ui/core/styles';
import {
  FormLabel,
  Switch
} from "@material-ui/core";
const StepEvent = () => {
  
  // const [checked, setValue] = React.useState();
  const { surveyStep, setSurveyStep } = useContext(SurveyContext);
  const handleChange = (event) => {
    // setValue(event.target.checked);
    setSurveyStep(surveyStep => ({ ...surveyStep,[event.target.name]:  event.target.checked}));
  };


  const methods = useForm({ mode: "onBlur" });
  
  let history = useHistory();
  if (!surveyStep.firstName) {
    history.push(`/survey/intro`);
  }

  const onSubmit = (data) => {
    // alert(JSON.stringify(surveyStep));
    history.push(`/survey/themes`);
  };
  const BlackSwitch = withStyles({
    switchBase: {
      color: '#fff',
      '&$checked': {
        color: '#FFF',
      },
      '&$checked + $track': {
        backgroundColor: '#000',
        opacity:1,
        height:'10px',
        margin:'2px 0',
      },
     '&$checked .MuiIconButton-label .MuiSwitch-thumb':{
        border: '2px solid  #000'
      }
    },
    thumb: {
      boxShadow: 'none',
    },
    checked: {},
    track: {},
  })(Switch);
  const eventTypeList = {
    "Congress":"Congress",
    "Conference":"Conference",
    "Convention":"Convention",
    "Congregation":"Congregation",
    "Symposium":"Symposium",
    "Meeting":"Meeting",
    "Fair":"Fair",
    "Summit":"Summit",
    "Forum":"Forum",
    "Assembly":"Assembly",
    "Conclave":"Conclave",
    "Seminar":"Seminar",
    "Rally":"Rally",
    "Association":"Association",
    "Corporate":"Corporate",
    "Sport":"Sport",
    "Private":"Private"
  };

  const eventDescriptorList ={
    "African":"African",
    "American":"American",
    "Asian":"Asian",
    "European":"European",
    "Global":"Global",
    "Nordic":"Nordic",
    "Northern":"Northern",
    "Eastern":"Eastern",
    "Southern":"Southern",
    "Western":"Western",
    "National":"National",
    "International":"International",
    "Continental":"Continental",
    "Scandinavian":"Scandinavian",
    }
    
  const rotationList = {
    1 : "1st",
    2 : "2nd",
    3 : "3rd",
    4 : "4th",
    5 : "5th",
    6 : "6th",
    7 : "7th",
    8 : "8th",
    9 : "9th",
    10: "10th"
  }

  const openYearList = {
    "2022": "2022",
    "2023": "2023",
    "2024": "2024",
    "2025": "2025",
    "2026": "2026",
    "2027": "2027",
    "2028": "2028",
    "2029": "2029",
    "2030": "2030",
    "2031": "2031"
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <ProgressBar title="About your Event" pct="50" step={2} />
        <Grid container justifyContent="center" spacing={5}>

        <Grid item xs={12} md={10} >
        <Grid container spacing={2} style={{padding:'8px'}}>
        <FormHelperText component="div"  style={{width:'100%'}}>
          <Typography component="div" variant='subtitle1' color="primary" >
            <Box>
            What kind of event are you planning?
            </Box>
          </Typography>
        </FormHelperText>
        
          <Grid item xs={12} md={6}>
            <SimpleSelect
              required={true}
              fieldName={"eventType"}
              title={"What type of event is it?"}
              items={eventTypeList}
              fontStyle={'body1'}
            />
          </Grid>

          <Grid item xs={12} md={6}>
              <TextField
                  title={
                    "Any set rotation?"
                  }
                  content={
                    "set Rotation"
                  }
                  fieldName={"setRotation"}
                  termination={true}
                  type={'number'}
                  pattern={/^[0-9]+$/}
                /> 
          </Grid>
          <Grid item xs={12} md={6}>
            <SimpleSelect
              required={false}
              fieldName={"eventDescriptor"}
              title={"Any relevant descriptor?"}
              items={eventDescriptorList}
              fontStyle={'body1'}
            />
          </Grid>

          </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              style={{ backgroundColor: "#F5F5F5" }}
              spacing={3}
            >
              <Grid item xs={12} md={10}>
                <RadioButtonsGroup
                  defaultValue={"<500"}
                  disabled={false}
                  fieldName={"delegatesQty"}
                  title={"How many delegates do you expect?"}
                  values={["<500", "500-1000", "1000-5000", "5000+"]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={10}>
            <Grid
              container
              justifyContent="flex-start"
              direction="column"
              spacing={3}
              style={{ flexWrap: "nowrap" }}
            >
              <Grid item xs={12} md={6}>
                <SimpleSelect
                  required={true}
                  fieldName={"openYear"}
                  title={"What is the next open year for your event?"}
                  items={openYearList}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {/* <SwitchButton
                  title={"Do you have a preferred date?"}
                  right={"Yes"}
                  left={"Not yet"}
                /> */}
                  <Typography component="div">
                  <FormLabel component="legend" ><Typography variant="subtitle1" >{"Do you have a preferred date?"}</Typography></FormLabel>
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>{"Not yet"}</Grid>
                    <Grid item>
                      <BlackSwitch checked={surveyStep.preferedDate} onChange={handleChange} name="preferedDate"
                        inputProps={{ "aria-label": "secondary checkbox" }} />
                    </Grid>
                    <Grid item>{"Yes"}</Grid>
                  </Grid>
                </Typography>
              </Grid>
              {surveyStep.preferedDate &&<Grid item xs={12} md={6}>
              <Typography component="div"  variant="body1">
                <Box fontWeight="fontWeightBold">
                  Start date
                </Box>
                </Typography>
              <DatePickers fieldName={"startDate"}/>
              </Grid>}
              { surveyStep.preferedDate && <Grid item xs={12} md={6}>
              <Typography component="div"  variant="body1">
                <Box fontWeight="fontWeightBold">
                End date
                </Box>
                </Typography>
              <DatePickers fieldName={"endDate"}/>
              </Grid>}
             
              <Grid item xs={12}>
                <Required></Required>
              </Grid>

              <Grid container justifyContent="space-between">
                <Grid item xs={4} md={1}>
                  <Link to="/survey/about" style={{ textDecoration: "none" }} underline="none">
                    <Button variant="outlined" caption={"BACK"} />{" "}
                  </Link>
                </Grid>

                <Grid item  xs={4} md={'auto'}>
                  <Button
                    onClick={methods.handleSubmit(onSubmit)}
                    caption={"NEXT"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container justifyContent="flex-start" xs={12} md={10}>
          <Grid item xs={12}  md={5}>
          <DatePickers content={"Start date"} />
          </Grid>
          </Grid>

          <Grid container justifyContent="flex-start" xs={12} md={10}>
          <Grid item xs={12}  md={5}>
          <DatePickers content={"End date"} />
          </Grid>
          </Grid> */}

          {/* <Button
        caption={step + " button"}
        onClick={() => {
          setSurveyStep({ foo: step });
        }}
      />
      <Button
        caption={step + " button"}
        onClick={() => {
          setSurveyStep({ foo: step });
        }}
      /> */}

          {/* <Button
            caption={"save"}
            onClick={() => {
              setSurveyStep({ foo: step });
            }}
          /> */}
        </Grid>
      </form>
    </FormProvider>
  );
};

export default StepEvent;
