import React, {useContext, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { SurveyContext } from "../../utils/surveyContext";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginTop: theme.spacing(1),
      width: "100%",
    },
  },
}));
export default function FormPropsTextFields({disabled }) {
  const classes = useStyles();
  const { surveyStep, setSurveyStep } = useContext(SurveyContext);
  const [state, setState] = React.useState({
    workingTopic1:surveyStep['workingTopics']?surveyStep['workingTopics'][0]:'',
    workingTopic2:surveyStep['workingTopics']?surveyStep['workingTopics'][1]:'',
    workingTopic3:surveyStep['workingTopics']?surveyStep['workingTopics'][2]:'',
  });
  const handleChange = (event) => {
    setState(state => ({ ...state,[event.target.name]:  event.target.value}))  

  };
  useEffect(()=>{
    let array=[]
    Object.entries(state).map((entry) => {
      let [key, value] = entry;
      array.push(value)
    })
    setSurveyStep(surveyStep => ({ ...surveyStep,['workingTopics']:  array}))
  },[state])

  const { register,  control,formState: { errors }, } = useFormContext();

  return (
    <Box mt={0}>
        
        <Typography component="div" variant="subtitle1" color="primary">
           <Box style={{color:disabled?'#707070':'black'}}>
           Do you have any (working) topics for your event?
            </Box>
        </Typography>
        <FormHelperText component="div">
          <Typography component="div" variant={'body1'} color="primary">
            <Box mb={2} style={{color:disabled?'#707070':'black'}}>
            If yes, write the (working) topics
            </Box>
          </Typography>
        </FormHelperText>
            <TextField  
            inputProps={{style: {color:'#032584',fontSize: 18,fontWeight:300, padding:'0.25rem 1rem'}, maxLength:"500"}} 
            control={control} 
            defaultValue={state.workingTopic1}
            name="workingTopic1"
            id="standard-required"
            placeholder="Eg. Design for climate adoptation"
            onChange={handleChange}
            disabled={disabled} />
            <TextField  
            inputProps={{style: {color:'#032584',fontSize: 18,fontWeight:300, padding:'0.25rem 1rem'}, maxLength:"500"}} 
            control={control} 
            defaultValue={state.workingTopic2} 
            name="workingTopic2"
            id="standard-required"
            placeholder="Eg. News in neuroscience"
            onChange={handleChange}
            disabled={disabled} />
            <TextField  
            inputProps={{style: {color:'#032584',fontSize: 18,fontWeight:300, padding:'0.25rem 1rem'}, maxLength:"500"}} 
            control={control} 
            defaultValue={state.workingTopic3}  
            name="workingTopic3"
            id="standard-required"
            placeholder="Eg. Building resilience"
            onChange={handleChange}
            disabled={disabled} />
          </Box>
  );
}
