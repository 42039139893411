import React,  {useContext } from "react";
import { SurveyContext } from "../../utils/surveyContext";
import {
  FormControl,
  FormControlLabel,
  Typography,
  FormLabel,
  RadioGroup,
  Radio,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  asterisk: {
    color: theme.palette.text.hint,
    fontFamily: 'Montserrat',
    fontWeight:'400',
  },
}));
export default function RadioButtonsGroup({values, title,fieldName,disabled,defaultValue,required}) {
  const classes = useStyles();
  const { surveyStep, setSurveyStep } = useContext(SurveyContext);
  const handleChange = (event) => {
    setSurveyStep(surveyStep => ({ ...surveyStep,[event.target.name]:  event.target.value}))
  };
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
      <Typography component="div" color="primary">
        <Box fontWeight="fontWeightBold" style={{color:disabled?'#707070':'black'}} > {title} {required && <span className={classes.asterisk}> *</span>}</Box>
      </Typography>
      </FormLabel>
      <RadioGroup 
        aria-label={fieldName}
        name={fieldName} 
        value={surveyStep[fieldName]?surveyStep[fieldName] : defaultValue}
        onChange={handleChange}
      >
         {values.map(value =>
        <FormControlLabel
        style= {{color:disabled?'#000':'#032584',fontSize: 18,textTransform:'Capitalize', span:{fontWeight:300}}}
        disabled={disabled} key={value} value={value} control={<Radio style= {{marginRight:'1rem'}} />} label={value} />
      )}
      </RadioGroup>
    </FormControl>
  );
}
