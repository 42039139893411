import React, { useContext } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import { SurveyContext } from "../../utils/surveyContext";
import TextBlock from "../atoms/textBlock";
import Checkbox from "../atoms/singleCheckBox";
import Button from "../atoms/button";
import ProgressBar from "../molecules/progressBar";
import BasicTextFields from "../molecules/contactInfo";
import OutlinedCard from "../molecules/uploadCard";
import RadioButton from "../atoms/radioInput";
import Required from "../atoms/required";

import UploadButtons from "../atoms/fileUploader";

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  TextareaAutosize,
  Typography,
  FormLabel,
  RadioGroup,
  Box,
  Grid,
} from "@material-ui/core";
import ButtonUI from "@material-ui/core/button";

const StepEvent = () => {
  const step = "almost";
  const { surveyStep, setSurveyStep } = useContext(SurveyContext);
  let history = useHistory();

  if (!surveyStep.firstName) {
    history.push(`/survey/intro`);
  }

  /*  
  const procFile = async () => {
    const file = document.querySelector("[type=file]").files[0];
    if (file) {
      console.warn("yesfile");
      const fileurl = "https://api.cloudinary.com/v1_1/wonderfulcopenhagen/image/upload";
      const fileForm = new FormData();
      fileForm.append("file", file);
      fileForm.append("upload_preset", "logo_upload");
      return await fetch(fileurl, {
        method: "POST",
        body: fileForm,
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          const publicid = data.public_id;
          return publicid;
        })
        .then((id) => {
          return id;
        });
    } else {
      console.warn("nofile");
      return;
    }
  }; 
  */

  const procForm = async () => {
    const url = "https://europe-west1-copcapapp.cloudfunctions.net/formData";
    // const url = "http://localhost:5001/copcapapp/europe-west1/formData";

    // const file = await procFile();
    // console.log(file);

    return await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        surveyStep,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setSurveyStep((surveyStep) => ({
          ...surveyStep,
          resultId: data.id,
        }));
      });
  };

  const onSubmit = async () => {
    await procForm().then(() => {
      history.push(`/survey/end`);
    });
  };

  const [value, setValue] = React.useState("yes");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const methods = useForm({ mode: "onBlur" });
  return (
    <FormProvider {...methods}>
      <ProgressBar title="Almost There" pct="100" step={5} />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} md={10}>
            <Typography component="div" variant="body1">
              <Box fontWeight="fontWeightBold">
                That’s almost it, now we just need your details, so we can send
                you the link to your video
              </Box>
            </Typography>
            <Typography component="div" variant="body1">
              <Box pl={2} pt={4}>
                {surveyStep.firstName
                  ? JSON.parse(JSON.stringify(surveyStep.firstName))
                  : ""}{" "}
                &nbsp;
                {JSON.parse(JSON.stringify(surveyStep.lastName))}
              </Box>
            </Typography>
          </Grid>

          <Grid item xs={12} md={10}>
            <BasicTextFields />
          </Grid>

          <Grid item xs={12} style={{ backgroundColor: "#F5F5F5" }}>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={10}>
                <RadioButton
                  defaultValue={"no"}
                  disabled={value === "no" ? true : false}
                  fieldName={"IsForSomeoneElse"}
                  title={"Are you organising this event for someone else?"}
                  values={["yes", "no"]}
                  required={true}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item xs={12} md={10}>
            <Grid container alignItems="center" style={{ padding: "2rem 0" }}>
              <Grid item xs={12} md={6}>
                <Typography component="div" variant="body1">
                  <Box fontWeight="fontWeightBold">
                    Upload your logo (optional)
                  </Box>
                  The logo should be a PNG with a transparent background and at
                  least 500 pixels wide
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex" justifyContent="center" fontSize="14">
                  <label className="custom-file-upload">
                    <input type="file" name="file" id="uploadBtn" />
                    Choose File
                  </label>
                </Box>
              </Grid>
            </Grid>
          </Grid> */}

          <Grid item xs={12} >
            <Grid container justifyContent="center">
              <Grid item xs={12} md={10}>
                <Checkbox />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={10}>
            <Required></Required>
          </Grid>

          <Grid item xs={12} md={10}>
            <Grid container justifyContent="space-between">
              <Grid item xs={4} md={1}>
                <Link
                  to="/survey/interests"
                  style={{ textDecoration: "none" }}
                  underline="none"
                >
                  <Button variant="outlined" caption={"BACK"} />{" "}
                </Link>
              </Grid>
              <Grid item  xs={4} md={'auto'}>
                <ButtonUI
                  onClick={methods.handleSubmit(onSubmit)}
                  style={{
                    fontFamily: "Nobel",
                    fontSize: 14,
                    padding: "10px 40px",
                    borderRadius: "0",
                    backgroundColor: "#50BEAA",
                    color: "white",
                  }}
                >
                  <Box fontWeight="fontWeightBold"> SUBMIT</Box>
                </ButtonUI>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default StepEvent;
